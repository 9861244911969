.booking-details {
    margin-top: 50px;
    }
    .booking-details h2 {
        font-size: 40px;
        font-weight: 700;
        margin: 0;
    }
    .booking-details h6 {
        margin: 10px auto 0;
        font-size: 18px;
    }
    .center-block h2 {
        text-align: center;
        color: #3f4b5e;
        margin: 0 0 30px;
    }
    .center-block h6 {
        text-align: center;
        color: #8b9aab;
        font-weight: 300;
        margin: 10px auto 0;
        line-height: 1.6;
    }
    .details-area{
        margin-top: 3rem!important;
        margin-bottom: 6rem;
    }
    .preference-title{
        background: #45c3d3;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }
    .preference-title h4 {
        color: #fff;
        padding: 13px 16px;
        font-weight: 700;
        margin: 0;
    }
    
    .sticky-div {
        position: sticky;
        top: 50px;
        
    }
    .datepicker-div{
        padding:25px;
    }
    .card-area{
    /* background-color: #f0f8ff !important; */
    }
    .left-side{
        text-align: center;
    }
    .preference-radio{
        padding: 25px;
        margin-top: 0px!important;
    }
    .preference-radio p {
        font-size: 19px;
    }
    .styled-radio input[type=radio] {
        position: absolute;
        visibility: hidden;
        display: none;
    }
    .styled-radio input[type=radio]:checked+label {
        color: #10b1e7;
        border: 2px solid #10b1e7!important;
    }
    .styled-radio2 label {
        padding: 15px 10px;
        font-size: 14px;
        overflow: hidden;
    }
    .styled-radio label {
        color: #3f4b5e;
        display: inline-block;
        cursor: pointer;
        font-weight: 500;
        padding: 10px;
        width: 100%;
        border: 1px solid #e2e6e7;
        border-radius: 0px;
        text-align: center;
        transition: .3s;
    }
    .employee-select-container{
        width:100px;
    }
    
    div.scrollmenu {
        background-color: #333;
        overflow: auto;
        white-space: nowrap;
      }
      
      div.scrollmenu a {
        display: inline-block;
        color: white;
        text-align: center;
        padding: 14px;
        text-decoration: none;
      }
      
      div.scrollmenu a:hover {
        background-color: #777;
      }
    
      .preference-icon {
        text-align: center;
        border: 2px solid #e2e6e9;
        padding: 18px;
        margin: 0px 0 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .preference-icon img {
        width: 85px;
    }
    .preference-icon p {
        margin: 6px 0 0;
        font-weight: 700;
        font-size: 15px;
        color: #8b9aab;
    }
    
    
    
    
    
    .employee-time-area{
        padding: 25px;
        margin-top: 0px!important;
    }
    
    
    
    .employee-div{
        width: 50%;
    }
    .time-div{
        width: 50%;
    }
    .employee-counter  {
        max-width: 100%;
        height: 38px;
        border-right: 2px solid #e2e6e9;
        border-left: 2px solid #e2e6e9;
        border-bottom: 2px solid #e2e6e9;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: bg_base;
    }
    
    
    
    .counter__item {
        position: relative;
        float: left;
        width: 30%;
        height: 100%;
        cursor: pointer;
    }
    a:not([href]):not([tabindex]) {
        color: inherit;
        text-decoration: none;
    }
    .counter__minus, .counter__plus {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: center;
        padding: 7px 0;
        cursor: pointer;
        color: #e2e6e8!important;
    }
    
    .fa {
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .fa-minus:before {
        content: "\f068";
    }
    .counter__item--center {
        width: 40%;
        border-right: 2px solid #e2e6e9;
        border-left: 2px solid #e2e6e9;
    }
    button[disabled], html input[disabled] {
        cursor: default;
    }
    .counter-value-input {
            position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        padding: 0;
        width: 100%;
        font-size: 17px;
        font-weight: 700;
        color: #10b2e7;
        border: none;
        text-align: center;
        outline: none;
        background-color: #fff;
        cursor: default;
        line-height: normal;
    }
    
    
    
    .sm-box1 {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        margin-bottom: -5px !important;
    }
    label.radio-offer {
        font-size: 14px;
    }
    .optinal-textarea {
        border: 2px solid #d4d4d4;
        width: 100%;
        border-radius: 5px;
        height: 85px;
    }
    .next-button-tracker{
        background: #00969b;
    }
    .text-right{
        text-align: right;
    }
    .guest-login-confirm-div{
        padding-left: 35px;
        padding-top: 25px;
    }
    .card-title-booking-form-3{
        padding: 10px;
        background: #6c9cd291;
        color: #070337;
    }
    .form-switch .form-check-input:checked {
        background-position: right center;
        background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e);
        height: 25px;
        width: 40px;
    }
    .form-switch .form-check-input {
        width: 38px;
        margin-left: -2.5em;
        background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e);
        background-position: left center;
        border-radius : 2em;
        height: 25px;
    }
    .form-check-label{
        
        color: #070337;
        font-size: 16px;
        font-weight: 700;
        padding: 5px;
    }
    
    .fesilities {
        background: #fff;
        padding: 0px 20px 0;
    }
    
    .fesilities ul li {
        list-style: none;
        color: #bbbebd;
        margin: 10px 0;
        width: 100%;
    }
    .fesilities ul {
        padding: 0;
        margin: 0;
    }
    
    .fesilities ul li i {
        font-size: 22px;
        float: left;
        margin-top: 4px;
    }
    .fesilities ul li p {
        margin: 0 0 0 36px;
    }
    .fesilities ul li p {
        font-size: 16px;
        color: #6f7377;
        font-weight: 600;
    }
    
    .fesilities h4 span {
        color: #10b2e7;
        font-size: 25px;
        float: right;
        margin-top: 4px;
        line-height: .6;
    }
    
    .fesilities h4 {
        font-weight: 700;
        border-top: 1px solid #e2e6e7;
        padding: 12px 0;
        margin: 0;
    }
    
    
    .fesilities h5 span {
        color: #5d6568;
        font-size: 20px;
        float: right;
        margin-top: 4px;
        line-height: .6;
    }
    
    .fesilities h5 {
        font-weight: 700;
        border-top: 1px solid #e2e6e7;
        padding: 12px 0;
        margin: 0;
    }
    
    .spanpromo
    {
        font-size: 17px !important;
        color: #10b2e7 !important;
        font-weight: 500 !important;
    }
    /* #guest {
        display: none;
    }
    
    #login {
        display: none;
    }
    
    input[value="guest"]:checked ~ #guest {
        display: block;
    }
    
    input[value="login"]:checked ~ #login {
        display: block;
    } */
    
    .inputstyle {
        height: 18px;
        width: 20px;
        margin-bottom: -21px;
        margin-top: 6px;
        position: absolute;
    }
    .labelstyle {
        font-size: 18px;
        font-weight: 500;
        color: #1f1f1d;
        width: 100%;
        cursor: pointer;
        padding: 2px 13px 5px;
        text-align: center;
    }
    .btnset12
    {
        height: 45px !important;
        line-height: 0.5 !important;
        margin-bottom: 1rem !important;
        margin-top: 0px !important;
    }
    
    .tabs_style .react-tabs__tab--selected, .tabs_style .react-tabs__tab-panel--selected {
        background: #ffffff !important;
        border-color: #ffffff !important;
    }
    
    .tabs_style .react-tabs__tab {
        color: #808080c7;
        padding: 8px 2px 9px !important;
        font-size: 21px !important;
        width: 50%;
    }
    .tabs_style .react-tabs__tab-panel--selected {
        display: block;
        padding: 0.2rem 0.4rem !important;
    }
    
    .tabs_style .react-tabs__tab:focus:after {
        content: '';
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: #fff !important;
    }
    .tabs_style .react-tabs__tab--selected .labelstyle
    {
     background: #45c3d3;
    border-radius: 4px;
    color: white;

    }
    span.all-summery-portion {
        padding-left: 5px;
        font-size: 16px;
        color: #374e8c;
        font-weight: 700;
    }
.all-error-clss{
    font-size: 16px;
    color: red;
    font-weight: 500;
}  
span.name.mt-3 {
    font-size: 22px;
    font-weight: bold;
}  